<template>
  <div class="facture-compta-rh-sci">
    <b-button
      size="sm"
      class="button-export-style ml-2"
      title="Créer des factures"
      @click="fixDate"
      v-b-modal.addFactureRhCSCI
    >
      <font-awesome-icon icon="file-medical" class="file-medical" />
    </b-button>
    <b-modal
      id="addFactureRhCSCI"
      ref="addFactureRhCSCI"
      :hide-footer="true"
      :hide-header="true"
      no-close-on-backdrop
      @hidden="resetModal()"
      modal-class="cutsom-modal-bootstrap cutsom-modal-bootstrap-compta-rh-sci"
    >
      <div class="hader mb-2">
        <div class="titleSetting">Nouvelle facture</div>
        <div class="iconClose" @click.prevent="hideModal('addFactureRhCSCI')">
          <font-awesome-icon icon="times" />
        </div>
      </div>
      <Card>
        <template v-slot:body>
          <form
            @submit.prevent="handleSubmitNewFacture"
            class="form-modal-custom-style"
          >
            <b-row>
              <b-col col xs="4" sm="4" md="4">
                <b-form-group label="Année" label-for="year" class="mr-2">
                  <b-form-select
                    id="year"
                    v-model="filterYear"
                    :options="ListYear"
                    class="b-form-select-raduis"
                  ></b-form-select>
                </b-form-group>
              </b-col>
              <b-col col xs="4" sm="4" md="4">
                <b-form-group
                  label="Mois"
                  label-for="month"
                  class="input-modal-champ"
                >
                  <b-form-select
                    id="month"
                    v-model="filterMonth"
                    :options="computedGetMonthForYearTh"
                    text-field="text"
                    value-field="value"
                    class="b-form-select-raduis"
                    required
                  >
                  </b-form-select>
                </b-form-group>
              </b-col>
              <b-col col xs="3" sm="3" md="3">
                <b-form-group
                  label="Type de document"
                  label-for="month"
                  class="input-modal-champ type-doc-class"
                >
                  <b-form-checkbox
                    v-model="checked"
                    name="some-radios"
                    value="facture"
                    class="check-th-details ml-2"
                    >Facture</b-form-checkbox
                  >
                  <b-form-checkbox
                    v-model="checked"
                    name="some-radios"
                    value="proforma"
                    class="check-th-details ml-2"
                    >Proforma</b-form-checkbox
                  >
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col col xs="10" sm="10" md="10">
                <b-form-group
                  label="Société"
                  label-for="page"
                  class="vue-select-modal-champ"
                ></b-form-group>
                <multiselect
                  v-model="vendeurs"
                  :options="usersMensuel"
                  placeholder="Rechercher une société"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :showLabels="false"
                  :preselect-first="false"
                  label="name"
                  track-by="name"
                  class="select-vue-component-style mb-3"
                >
                  <span slot="noResult">Aucune société trouvé.</span>
                  <span slot="noOptions">Aucune société trouvé.</span>
                </multiselect>
              </b-col>
              <b-col col xs="2" sm="2" md="2">
                <b-form-checkbox
                  name="radios"
                  v-model="checkAll"
                  @change="selectAllSociete"
                  class="check-th-details ml-2"
                  style="padding-top: 32px; padding-left: 0px"
                  >Tous</b-form-checkbox
                >
              </b-col>
            </b-row>
            <b-row>
              <b-col col xs="10" sm="10" md="10">
                <b-form-group
                  label="Client"
                  label-for="page"
                  class="vue-select-modal-champ"
                ></b-form-group>
                <multiselect
                  v-model="clients"
                  :options="computedGetSettingFilialesTh"
                  placeholder="Rechercher un client"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :showLabels="false"
                  :preselect-first="false"
                  label="name"
                  track-by="name"
                  class="select-vue-component-style mb-3"
                >
                  <span slot="noResult">Aucune société trouvé.</span>
                  <span slot="noOptions">Aucune société trouvé.</span>
                </multiselect>
              </b-col>
              <b-col col xs="2" sm="2" md="2">
                <b-form-checkbox
                  name="radios"
                  v-model="checkAllClient"
                  @change="selectAllClient"
                  class="check-th-details ml-2"
                  style="padding-top: 32px; padding-left: 0px"
                  >Tous</b-form-checkbox
                >
              </b-col>
            </b-row>
            <div class="actionModel mt-3">
              <div class="messageError">
                <div v-if="error" class="error">
                  <ul v-if="Array.isArray(error)" class="mb-0">
                    <li v-for="(e, index) in error" :key="index">
                      {{ e }}
                    </li>
                  </ul>
                  <div v-else>{{ error }}</div>
                </div>
              </div>
              <b-button type="submit" class="button-valide-style">
                <span>
                  Valider
                  <div v-if="loading" class="loading ml-2">
                    <div class="spinner-border" role="status"></div>
                  </div>
                </span>
              </b-button>
            </div>
          </form>
        </template>
      </Card>
    </b-modal>
    <b-modal
      no-close-on-backdrop
      ref="proformatModal"
      id="proformatModal"
      size="lg"
      :hide-footer="true"
      :hide-header="true"
      @hidden="resetModal()"
      modal-class="cutsom-modal-bootstrap"
      class="justify-content-center"
    >
      <div class="hader mb-2">
        <div class="titleSetting"></div>
        <div
          class="iconClose"
          @click.prevent="
            hideModal('proformatModal');
            showModal('errorModal');
          "
        >
          <font-awesome-icon icon="times" />
        </div>
      </div>
      <div class="d-flex justify-content-center">
        <b>Êtes-vous sûr de vouloir régénérer ses factures proforma?</b>
      </div>
      <div class="d-flex justify-content-center">
        <b-table
          show-empty
          id="my-table"
          class="
            custom-table-style
            customTableFactureLibreVueDefault
            table-header
          "
          :fields="fields"
          :items="listProformatAlredyExiste"
          bordered
          sticky-header
          hover
          head-variant="light"
          tbody-tr-class="ligneNormale"
          empty-text="Il n'y a aucun enregistrement à afficher"
        >
          <template class="check-th" v-slot:head()="data">
            <div v-if="data.field.key === 'check_all'">
              <b-form-checkbox
                v-model="checkAllfacturesProformat"
                @change="checkAllFunction"
                class="check-th"
                :value="true"
                :unchecked-value="false"
              >
              </b-form-checkbox>
            </div>
          </template>
          <template v-slot:cell(check_all)="data"
            >{{ data.item.check_all }}
            <b-form-checkbox
              v-model="data.item.check"
              class="check-th"
              :value="true"
              :unchecked-value="false"
            >
            </b-form-checkbox>
          </template>
          <template v-slot:cell(societe)="data">
            {{ data.item.vendeur }}
          </template>
          <template v-slot:cell(client)="data">
            {{ data.item.client }}
          </template>
          <template v-slot:cell(num-facture)="data">
            {{ data.item.numero }}
          </template>
          <template v-slot:cell(montant)="data">
            {{ data.item.montant }}
          </template>
        </b-table>
      </div>
      <div
        v-if="computedChekedProformat.length > 0"
        class="d-flex justify-content-center"
      >
        <b-button
          class="button-valide-style"
          @click.stop.prevent="regenerateFacturs"
        >
          <span>
            Valider
            <div v-if="updateProformatLoding" class="loading ml-2">
              <div class="spinner-border" role="status"></div>
            </div>
          </span>
        </b-button>
      </div>
    </b-modal>
    <b-modal
      no-close-on-backdrop
      ref="errorModal"
      id="errorModal"
      size="lg"
      :hide-footer="true"
      :hide-header="true"
      @hidden="resetModal()"
      modal-class="cutsom-modal-bootstrap"
    >
      <div class="hader mb-2">
        <div class="titleSetting"></div>
        <div class="iconClose" @click.prevent="hideModal('errorModal')">
          <font-awesome-icon icon="times" />
        </div>
      </div>
      <!-- <Card> -->
      <b-row>
        <b-col class="col-6">
          <Card v-if="listFactureSuccee.length">
            <template v-slot:body>
              <b style="color: black"
                >Factures ajouté avec succès ({{ listFactureSuccee.length }}):
              </b>
              <b-table-simple
                class="table-fiche custom-table-style mb-0"
                responsive
              >
                <b-tbody>
                  <b-tr class="title-ligne">
                    <b-th class="newcolor">Societe</b-th>
                    <b-th class="newcolor">Client</b-th>
                    <b-th class="newcolor">Numero Facure</b-th>
                    <b-th class="newcolor">Montant</b-th>
                  </b-tr>
                  <b-tr
                    v-for="(item, index) in listFactureSuccee"
                    v-bind:key="index"
                  >
                    <b-td>{{ item.vendeur }}</b-td>
                    <b-td>{{ item.client }}</b-td>
                    <b-td>{{ item.num }}</b-td>
                    <b-td>{{ item.montant }} €</b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </template>
          </Card>
        </b-col></b-row
      >
      <b-row>
        <b-col class="col-6">
          <Card v-if="listFacturesExiste.length">
            <template v-slot:body>
              <b style="color: black"
                >Factures existe déja ({{ listFacturesExiste.length }}) :</b
              >
              <b-table-simple
                class="table-fiche custom-table-style mb-0"
                responsive
              >
                <b-tbody>
                  <b-tr class="title-ligne">
                    <b-th class="newcolor">Societe</b-th>
                    <b-th class="newcolor">Client</b-th>
                    <b-th class="newcolor">Numero Facure</b-th>
                    <b-th class="newcolor">Montant</b-th>
                  </b-tr>
                  <b-tr
                    v-for="(item, index) in listFacturesExiste"
                    v-bind:key="index"
                  >
                    <b-td>{{ item.vendeur }}</b-td>
                    <b-td>{{ item.client }}</b-td>
                    <b-td>{{ item.numero }}</b-td>
                    <b-td>{{ item.montant }} €</b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </template>
          </Card>
        </b-col>
        <b-col class="col-6">
          <Card v-if="listFactureUpdated.length">
            <template v-slot:body>
              <b style="color: black"
                >Proforma régénérée avec succès ({{
                  listFactureUpdated.length
                }}):
              </b>
              <b-table-simple
                class="table-fiche custom-table-style mb-0"
                responsive
              >
                <b-tbody>
                  <b-tr class="title-ligne">
                    <b-th class="newcolor">Societe</b-th>
                    <b-th class="newcolor">Client</b-th>
                    <b-th class="newcolor">Numero Facure</b-th>
                    <b-th class="newcolor">Montant</b-th>
                  </b-tr>
                  <b-tr
                    v-for="(item, index) in listFactureUpdated"
                    v-bind:key="index"
                  >
                    <b-td>{{ item.vendeur }}</b-td>
                    <b-td>{{ item.client }}</b-td>
                    <b-td>{{ item.num }}</b-td>
                    <b-td>{{ item.montant }} €</b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </template>
          </Card>
        </b-col>
      </b-row>
      <b-row class="mt-4">
        <b-col>
          <Card v-if="listSocieteErrorRib.length">
            <template v-slot:body>
              <b style="color: black"
                >Société n'avait pas de RIB ({{
                  listSocieteErrorRib.length
                }}):</b
              >
              <ul style="list-style-type: none">
                <li
                  v-for="(item, index) in listSocieteErrorRib"
                  v-bind:key="index"
                  style="color: black"
                >
                  {{ item }}
                </li>
              </ul>
            </template>
          </Card>
        </b-col>
        <b-col>
          <Card v-if="listSocieteError.length">
            <template v-slot:body>
              <b style="color: black"
                >Société a des erreur de séquance ({{
                  listSocieteError.length
                }}):</b
              >
              <ul style="list-style-type: none">
                <li
                  v-for="(item, index) in listSocieteError"
                  v-bind:key="index"
                  style="color: black"
                >
                  {{ item }}
                </li>
              </ul>
            </template>
          </Card>
        </b-col>
      </b-row>
      <b-row class="mt-4"
        ><b-col>
          <Card v-if="vendeurs_without_configs.length">
            <template v-slot:body>
              <b style="color: black"
                >Société n'a pas de configuration ({{
                  vendeurs_without_configs.length
                }}):</b
              >
              <ul style="list-style-type: none">
                <li
                  v-for="(item, index) in vendeurs_without_configs"
                  v-bind:key="index"
                  style="color: black"
                >
                  {{ item }}
                </li>
              </ul>
            </template>
          </Card>
        </b-col></b-row
      >
      <!-- </Card> -->
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
export default {
  props: {
    type_produit: { required: true }
  },
  data() {
    return {
      ListTypeDoc: [
        { text: 'Facture', value: 'facture' },
        { text: 'Proforma', value: 'proforma' }
      ],
      fields: [
        {
          key: 'check_all',
          label: '',
          show: true
        },
        {
          key: 'societe',
          label: 'Societe',
          isSortable: true
        },
        {
          key: 'client',
          label: 'client',
          isSortable: true
        },
        {
          key: 'num-facture',
          label: 'Num Facture',
          isSortable: true
        },
        {
          key: 'Montant',
          label: 'montant',
          isSortable: true
        }
      ],
      checkAllfacturesProformat: false,
      listFacturesExiste: [],
      listFactureSuccee: [],
      listSocieteError: [],
      listSocieteErrorRib: [],
      vendeurs_without_configs: [],
      listProformatAlredyExiste: [],
      listFactureUpdated: [],
      factursToRegenerate: [],
      vendeurs: [],
      clients: [],
      checked: 'proforma',
      error: null,
      loading: false,
      filterMonth: null,
      filterYear: null,
      ListYear: [],
      checkAll: false,
      checkAllClient: false,
      updateProformatLoding: false
    };
  },
  computed: {
    ...mapGetters([
      'getMonthForYearTh',
      'usersMensuel',
      'getSettingFilialesTh'
    ]),
    computedGetSettingFilialesTh() {
      return this.getSettingFilialesTh.filter(
        item => item.type != 'particulier' && item.type != 'passage'
      );
    },
    computedGetMonthForYearTh() {
      return [
        { text: 'Janvier', value: 1 },
        { text: 'Février', value: 2 },
        { text: 'Mars', value: 3 },
        { text: 'Avril', value: 4 },
        { text: 'Mai', value: 5 },
        { text: 'Juin', value: 6 },
        { text: 'Juillet', value: 7 },
        { text: 'Aôut', value: 8 },
        { text: 'Septembre', value: 9 },
        { text: 'Octobre', value: 10 },
        { text: 'Novembre', value: 11 },
        { text: 'Décembre', value: 12 }
      ];
    },
    computedChekedProformat() {
      let computedProformatToRegenerate = [];
      this.listProformatAlredyExiste.map(item => {
        if (item.check === true) {
          computedProformatToRegenerate.push(item);
        }
      });
      return computedProformatToRegenerate;
    }
  },
  methods: {
    ...mapActions([
      'getFilialeByUser',
      'fetchMonthForYearTH',
      'addFactureMensuel',
      'getClientOnligneUsers',
      'regenerateProformat',
      'getSettingFilialeTh'
    ]),
    selectAllSociete() {
      if (this.checkAll == true) {
        this.usersMensuel.map(societe => {
          this.vendeurs.push(societe);
        });
      } else {
        this.vendeurs = [];
      }
    },
    checkAllFunction() {
      this?.listProformatAlredyExiste?.map(
        item => (item.check = this.checkAllfacturesProformat)
      );
    },
    selectAllClient() {
      if (this.checkAll == true) {
        this.getSettingFilialesTh.map(client => {
          this.clients.push(client);
        });
      } else {
        this.clients = [];
      }
    },
    resetModal() {
      this.vendeurs = [];
      this.clients = [];
      this.updateProformatLoding = false;
      this.error = null;
      this.loading = false;
      this.filterMonth = null;
      this.filterYear = null;
      this.ListYear = [];
      this.checked = 'proforma';
    },
    hideModal(ref) {
      this.$refs[ref].hide();
      this.$emit('reloadData');
      this.resetModal();
    },
    showModal(ref) {
      this.$refs[ref].show();
    },
    fixDate() {
      var courantYear = moment().year();
      var courantMonthId = moment().month();
      this.filterYear = courantYear;
      this.filterMonth = courantMonthId + 1;
      for (var i = 2019; i <= courantYear + 2; i++) {
        this.ListYear.push({ value: i, text: i });
      }
    },
    async regenerateFacturs() {
      this.updateProformatLoding = true;
      let bodyFormData = new FormData();
      for (let i = 0; i < this.computedChekedProformat?.length; i++) {
        bodyFormData.append(
          'ids[' + i + ']',
          this.computedChekedProformat[i].id
        );
      }
      const response = await this.regenerateProformat(bodyFormData);
      if (response.succes) {
        this.updateProformatLoding = false;
        this.listFactureUpdated = response.message.success;
        this.hideModal('proformatModal');
        this.showModal('errorModal');
      } else {
        this.updateProformatLoding = false;
      }
    },
    async handleSubmitNewFacture() {
      this.loading = true;
      let bodyFormData = new FormData();
      bodyFormData.append('annee', this.filterYear);
      bodyFormData.append('mois', this.filterMonth);
      bodyFormData.append('type', this.checked);
      bodyFormData.append('type_produit', this.type_produit);
      for (let i = 0; i < this.vendeurs.length; i++) {
        bodyFormData.append('vendeur_ids[' + i + ']', this.vendeurs[i].id);
      }
      for (let i = 0; i < this.clients.length; i++) {
        bodyFormData.append('acheteur_ids[' + i + ']', this.clients[i].id);
      }
      const response = await this.addFactureMensuel(bodyFormData);

      if (response.succes) {
        this.error = '';
        this.loading = false;
        this.listFacturesExiste = response.message.already_exist;
        this.listFactureSuccee = response.message.success;
        this.listSocieteError = response.message.error_seq;
        this.listSocieteErrorRib = response.message.error_rib;
        this.listProformatAlredyExiste = response.message.proforma_already_exist.map(
          item => {
            return { ...item, check: false };
          }
        );
        this.vendeurs_without_configs =
          response.message.vendeurs_without_configs;
        if (
          response?.message?.proforma_already_exist?.length > 0 &&
          this.checked === 'proforma'
        ) {
          this.showModal('proformatModal');
        } else {
          this.showModal('errorModal');
        }
        this.hideModal('addFactureRhCSCI');
      } else {
        this.error = response.error;
        this.loading = false;
      }
    }
  },
  components: {
    Card: () => import('@/views/component/card.vue')
  },
  async mounted() {
    var courantMonthId = moment().month();
    var courantYear = moment().year();
    this.year = courantYear;
    await this.fetchMonthForYearTH(this.year);
    var courantMonth = this.getMonthForYearTh[courantMonthId]
      ? this.getMonthForYearTh[courantMonthId].month_name
      : this.filterMonth;
    this.filterMonth = courantMonth;

    await this.getFilialeByUser();
    await this.getSettingFilialeTh();

    // await this.getClientOnligneUsers();
  }
};
</script>

<style lang="scss" scoped>
.facture-compta-rh-sci {
  .file-medical {
    font-size: 13px;
  }
  .color-text {
    color: black;
  }
  .list-type {
    list-style-type: none;
  }
}
</style>
<style lang="scss">
.cutsom-modal-bootstrap-compta-rh-sci .modal-dialog {
  top: 2% !important;
  max-width: 55% !important;
  margin: auto;
  #info-wrap {
    overflow: hidden;
  }
  .type-doc-class > div {
    display: flex;
  }
}

@media screen and (max-width: 925px) {
  .cutsom-modal-bootstrap-compta-rh-sci .modal-dialog {
    top: 2%;
    max-width: 70%;
    margin: auto;
  }
}
</style>
